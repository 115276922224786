import '../css/form.css';
import React from 'react';
import { Formik, Form} from 'formik';
import { TextField } from './textField';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import {MdDone} from "react-icons/md";

export const EditUserForm = (props) => {

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validate = Yup.object({
    First_Name__c: Yup.string().required('Required'),
    Last_Name__c: Yup.string().required('Required'),
    Email_Adress__c: Yup.string().email('Email is not valid').required('Required'),
    Valid_From__c: Yup.date(),
    Valid_To__c: Yup.date().min(Yup.ref('Valid_From__c'), 'end date can\'t be before start date'),

  });
  const initValue = {
    First_Name__c: props.initValue.Name,
    Last_Name__c: props.initValue.LastName,
    Email_Adress__c: props.initValue.Email,
    Valid_From__c: props.initValue.ValidFrom,
    Valid_To__c: props.initValue.ValidTo,
    External_Admin__c: props.initValue.Admin,
    Id: props.initValue.UserId
  };

  return (
    <Formik initialValues={initValue} validationSchema={validate} validateOnChange={true} validateOnBlur={false} onSubmit={props.submit}>
      {({Formik, values, errors}) => (
        <Form noValidate>
          <TextField label="First Name" name="First_Name__c" type="text"/>
          <TextField label="Last Name" name="Last_Name__c" type="text"/>
          <TextField label="Email" name="Email_Adress__c" type="email"/>
          <TextField label="Valid From" name="Valid_From__c" type="date"/>
          <TextField label="Valid To" name="Valid_To__c" type="date"/>
          <TextField label="External Admin" name="External_Admin__c" type="checkbox"/>
          <button className="btn btn-edit mt-3 addUser updateUser" type="submit">
            <MdDone className="addIcon mr-2"/>
            {props.isEditing && props.isEditing ? <Spinner animation="border"/> : 'Update User'}
          </button>
        </Form>
      )}
    </Formik>
  );
};


