import React from 'react';
import '../css/header.css';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import logo from '../images/logo.png';

export const Header = (props) => {
  return (
    <header className="w-100 sticky-top">
      <div className="row navbar">
        <div className="logo col-4"><img src={logo} alt="Logo"/></div>
        <div className="col-4 appName">Data Access Management</div>
        <div className="col-4"><AmplifySignOut/></div>
      </div>
    </header>
  );
};



