import React from 'react';
import { ErrorMessage, useField } from 'formik';
import '../css/textField.css'; /*added to overwrite some default bootstrap*/

export const TextField = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <div className="textField">
      <label className={props.type === 'checkbox' && 'form-check-label'} htmlFor={field.name}>{label}</label>
      <input
        className={props.type !== 'checkbox' && `form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
        {...field} {...props}
        autoComplete="off"
      />
      <ErrorMessage component="div" className="error" name={field.name}/>
    </div>
  );
};
