/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:97bb2b3c-c357-4332-99f7-7bc49f2d71d2",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_LuL9CEnCY",
    "aws_user_pools_web_client_id": "1so5vot4tm2v34on0rkpibn7",
    "oauth": {}
};


export default awsmobile;