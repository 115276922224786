import '../css/form.css';
import React from 'react';
import { Formik, Form } from 'formik';
import { TextField } from './textField';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { MdDone } from 'react-icons/md';

export const EditPremissionForm = (props) => {

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validate = Yup.object({
    Valid_From__c: Yup.date(),
    Valid_To__c: Yup.date().min(Yup.ref('Valid_From__c'), 'end date can\'t be before start date'),

  });
  const initValue = {
    Valid_From__c: props.initValue.ValidFrom,
    Valid_To__c: props.initValue.ValidTo,
    Id: props.initValue.Id
  };

  return (
    <Formik initialValues={initValue} validationSchema={validate} validateOnChange={true} validateOnBlur={false} onSubmit={props.submit}>
      {({Formik, values, errors}) => (
        <Form noValidate>
          <TextField label="Valid From" name="Valid_From__c" type="date"/>
          <TextField label="Valid To" name="Valid_To__c" type="date"/>
          <button className="btn btn-info mt-3 addUser updatePerm" type="submit">
            <MdDone className="addIcon mr-2"/>
            {props.isPremmisionUpdate && props.isPremmisionUpdate ? <Spinner animation="border"/> : 'Update'}
          </button>
        </Form>
      )}
    </Formik>
  );
};


