import '../css/userBox.css';
import React, { useState, useEffect } from 'react';
import { VerticallyCenteredModal } from './modal';
import { MdModeEdit, MdAdd, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import { EditUserForm } from './editUser';
import { EditPremissionForm } from './editPremission';
import { AddPremissionForm } from './addPermission';
import dayjs from 'dayjs';

export const UserBox = (props) => {
    const today = dayjs().format('YYYY-MM-DD');
    const [isExpanded, setIsExpanded] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [editPremissionShow, setEditPremissionShow] = useState(null);
    const [addPremmision, setAddPremission] = useState(false);

    useEffect(() => {
        if (props.isPremmisionUpdate === false) {
            setEditPremissionShow(null);
        }
        if (props.isEditing === false) {
            setModalShow(false);
        }
        if (props.isPremissionAdd === false) {
            setAddPremission(false);
        }
    }, [props.isPremmisionUpdate, props.isEditing, props.isPremissionAdd]);

    return (
        <div className={`userBox ${props.owner ? 'firstUser' : ''}`}>
            <div
                className="userName"
                onClick={() => setIsExpanded(!isExpanded)}
            >
        <span>
          {props.name} {props.lastName}
            {props.admin && <span className="userAdmin">Admin</span>}
        </span>
                {isExpanded ? (
                    <MdKeyboardArrowUp size={24} />
                ) : (
                    <MdKeyboardArrowDown size={24} />
                )}
            </div>

            {isExpanded && (
                <div className="user-content">
                    <p className="userEmail">{props.email}</p>
                    <p className="userValid">Valid: {props.validFrom} - {props.validTo}</p>

                    <div className="permissions-container">
                        <p>Permissions</p>
                        <div>
                            {props.p.map((i, index) => {
                                const status = i.Valid_From__c > today || i.Valid_To__c < today ? 'inactive' : '';
                                return (
                                    <React.Fragment key={index}>
                                        <VerticallyCenteredModal
                                            title={props.owner === false ? 'Edit Permission' : 'Permission Info'}
                                            show={editPremissionShow === index}
                                            onHide={() => setEditPremissionShow(null)}
                                        >
                                            <div className="bg-light bg-gradient p-3 mb-3">
                                                <p>User: {props.email}</p>
                                                <p>Access Area: {i.Name}</p>
                                                <p>Valid: {i.Valid_From__c} - {i.Valid_To__c}</p>
                                                <p>Status: {status ? 'Inactive' : 'Active'}</p>
                                            </div>
                                            {!props.owner && (
                                                <EditPremissionForm
                                                    initValue={{
                                                        ValidTo: i.Valid_To__c,
                                                        ValidFrom: i.Valid_From__c,
                                                        Id: i.Id
                                                    }}
                                                    submit={props.premissionEdit}
                                                    isPremmisionUpdate={props.isPremmisionUpdate}
                                                />
                                            )}
                                        </VerticallyCenteredModal>
                                        <Button
                                            onClick={() => setEditPremissionShow(index)}
                                            className={`editPerm ${status}`}
                                            variant="outline-success"
                                        >
                                            {i.Name}
                                        </Button>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>

                    {!props.owner && (
                        <div className="mt-4 pt-4 border-t">
                            <button
                                className="editUser btn btn-outline-info"
                                onClick={() => setModalShow(true)}
                            >
                                <MdModeEdit className="mr-2"/> Edit User
                            </button>
                            <button
                                className="addPermission-btn btn btn-outline-success"
                                onClick={() => setAddPremission(true)}
                            >
                                <MdAdd className="mr-2"/>Add Permission
                            </button>
                        </div>
                    )}
                </div>
            )}

            <VerticallyCenteredModal
                title="Edit User"
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <EditUserForm
                    initValue={{
                        Name: props.name,
                        LastName: props.lastName,
                        Email: props.email,
                        Admin: props.admin,
                        ValidTo: props.validTo,
                        ValidFrom: props.validFrom,
                        UserId: props.id
                    }}
                    areas={props.areas}
                    submit={props.edit}
                    isEditing={props.isEditing}
                />
            </VerticallyCenteredModal>

            <VerticallyCenteredModal
                title="Add Permission"
                show={addPremmision}
                onHide={() => setAddPremission(false)}
            >
                <AddPremissionForm
                    isAdding={props.isPremissionAdd}
                    submit={props.addPremission}
                    UserId={props.id}
                    areas={props.areas}
                    areasAttached={props.p}
                />
            </VerticallyCenteredModal>
        </div>
    );
};