import '../css/form.css';
import React from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import { TextField } from './textField';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { MdAdd, MdPersonAdd } from 'react-icons/md';

const date = (new Date()).toISOString().split('T')[0];

export class Area {
  constructor () {
    this.name = '';
    this.Valid_From__c = date;
    this.Valid_To__c = '2100-01-01';
  }
}

export const AddUserForm = (props) => {

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validate = Yup.object({
    First_Name__c: Yup.string().required('Required'),
    Last_Name__c: Yup.string().required('Required'),
    Email_Adress__c: Yup.string().email('Email is not valid').required('Required')
      .test('user-exist', 'User Exist', (value) => !props.emails.includes(value)),
    Valid_From__c: Yup.date().min(today, 'Date cannot be in the past').required('Start Date is Required'),
    Valid_To__c: Yup.date().min(Yup.ref('Valid_From__c'), 'end date can\'t be before start date'),
    areas: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .required('Name is required'),
          Valid_From__c: Yup.date().min(today, 'Date cannot be in the past').typeError('Date is not valid').required('Start Date is Required'),
          Valid_To__c: Yup.date().min(Yup.ref('Valid_From__c'), 'end date can\'t be before start date')
        })
      ).min(1, 'Need at least one Permission')

  });
  const initValue = {
    Creator_email__c: props.userLogged,
    First_Name__c: '',
    Last_Name__c: '',
    Email_Adress__c: '',
    Valid_From__c: date,
    Valid_To__c: '2100-01-01',
    External_Admin__c: false,
    Id: '',
    areas: []
  };

  const FriendArrayErrors = errors => typeof errors.areas === 'string' ? <div className="error">{errors.areas}</div> : null;

  return (
    <Formik initialValues={initValue} validationSchema={validate} validateOnChange={true} validateOnBlur={false} onSubmit={props.submit}>
      {({Formik, values, errors}) => (
        <div>
          <Form noValidate>
            <TextField name="Creator_email__c" type="hidden"/>
            <TextField label="First Name" name="First_Name__c" type="text"/>
            <TextField label="Last Name" name="Last_Name__c" type="text"/>
            <TextField label="Email" placeholder="example@mail.com" name="Email_Adress__c" type="email"/>
            <TextField label="Valid From" name="Valid_From__c" type="date"/>
            <TextField label="Valid To" name="Valid_To__c" type="date"/>
            <div className="form-check pl-0 pt-3 pb-3 pl-2">
              <TextField label="External Admin" name="External_Admin__c" type="checkbox"/>
            </div>
            <label className="mt-0">Access Areas</label>
            <div>

              <FieldArray name="areas" render={({remove, push}) => (

                <div className={'mb-1'}>
                  {values.areas.length > 0 && values.areas.map((area, index) => (
                    <div className="row mb-3 d-flex align-items-center" key={index}>
                      <div className="col">
                        <button type="button" className="btn-danger cancelAddAccess" onClick={() => remove(index)}>X</button>
                      </div>
                      <div className="col-12">
                        <div className="textField">
                          <Field as="select" className="form-control" name={`areas.${index}.name`}>
                            <option value="" defaultValue="selected">--</option>
                            {props.areas.map((i, index) => {
                              return <option key={index} value={i.Id}>{i.Name}</option>;
                            })}
                          </Field>
                          <ErrorMessage component="div" className="error" name={`areas.${index}.name`}/>
                        </div>
                        <TextField label="Valid From" name={`areas[${index}].Valid_From__c`} type="date"/>
                        <TextField label="Valid To" name={`areas[${index}].Valid_To__c`} type="date"/>
                      </div>
                    </div>
                  ))}
                  <button type="button" className="btn addPerm addUser btn-info btn-block pt-3 pb-3" onClick={() => push(new Area())}>
                    <MdAdd className="addIcon mr-1"/>
                    Add Permission
                  </button>
                </div>
              )}
              />
              {FriendArrayErrors(errors)}
            </div>

            <br/>

            {/*
            <div className={'col-12'}>
              <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              <pre>Values: {JSON.stringify(values, null, 2)}</pre>
            </div>
*/}
            <button className="btn btn-success mt-3 saveUser addUser btn-block" type="submit">
              <MdPersonAdd className="addIcon mr-2"/>
              {props.isSubmitting && props.isSubmitting ? <Spinner animation="border"/> : 'Save User'}
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};


