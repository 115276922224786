import '../css/form.css';
import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField } from './textField';
import * as Yup from 'yup';
import Spinner from 'react-bootstrap/Spinner';
import { MdAdd } from 'react-icons/md';

export const AddPremissionForm = (props) => {

  console.log(props);

  const areasAttached = props.areasAttached.map(a => a.BI_Access_Area__c);

  const validate = Yup.object({
    BI_Access_Area__c: Yup.string().required('Required')
      .test('area-exist', 'Area Exist', (value) => !areasAttached.includes(value)),
    Valid_From__c: Yup.date().required('Start Date is Required'),
    Valid_To__c: Yup.date().min(Yup.ref('Valid_From__c'), 'end date can\'t be before start date')

  });
  const initValue = {
    BI_User_Profile__c: props.UserId,
    BI_Access_Area__c: '',
    Valid_From__c: '',
    Valid_To__c: ''
  };

  return (
    <Formik initialValues={initValue} validationSchema={validate} validateOnChange={true} validateOnBlur={false} onSubmit={props.submit}>
      {({Formik, values, errors}) => (
        <Form noValidate>
          <div className="row mb-3 d-flex align-items-center">
            <div className="col-12">
              <div className="textField">
                <Field as="select" className="form-control" name={`BI_Access_Area__c`}>
                  <option value="" defaultValue="selected">--</option>
                  {props.areas.map((i, index) => {
                    return <option key={index} value={i.Id}>{i.Name}</option>;
                  })}
                </Field>
                <ErrorMessage component="div" className="error" name={`BI_Access_Area__c`}/>
              </div>
              <TextField label="Valid From" name={`Valid_From__c`} type="date"/>
              <TextField label="Valid To" name={`Valid_To__c`} type="date"/>
            </div>
          </div>
          <button className="save-btn btn btn-info mt-3 addUser " type="submit">
            <MdAdd className="addIcon mr-2"/>
            {props.isAdding && props.isAdding ? <Spinner animation="border"/> : 'SAVE'}
          </button>
        </Form>
      )}
    </Formik>
  );
};


